import React from 'react';
import Layout from '../components/Layout';
// const img = require('../assets/images/fake_site.png');

const IndexPage = () => (
  <Layout>
    {/* <div
      style={{
        background: `url(${img})`,
        height: window.innerHeight,
        width: window.innerWidth,
      }}
    ></div> */}
  </Layout>
);

export default IndexPage;
